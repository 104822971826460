import Text from "@atoms/Text";
import texts from '@components/Homepage/en.json';
import { FinancialProsperityData } from "@helpers/homepageData";
import Image from "@atoms/Image";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Lottie from 'lottie-react';
import webAnimation from '../../../public/assets/string-animation.json';
import mobileAnimation from '../../../public/assets/mobileanimation.json';

const FinancialProsperity = () => {
    const { width } = useWindowDimensions();
    return (
        <div className="relative overflow-hidden  px-4 md:px-32 xl:px-0 pb-12 md:pb-[120px] xl:py-18">
            <div className="max-width-wrapper pl-14 md:pl-20 xl:pl-0">
                <Text content={texts?.FinancialProsperityHeader} htmlTag="h2" className="h-[96px] md:h-[80px] xl:h-auto mt-[52px] md:mt-12 xl:mt-0 text-start xl:text-center text-primary-500 h4-semibold md:h3-semibold xl:h2-semibold max-w-[350px] md:max-w-none" />
                <div className="w-full xl:ml-[-40px] xl:flex xl:justify-center xl:items-center">
                    <div className="mt-[72px] md:mt-[72px] xl:mt-[123px] grid grid-cols-1 max-w-max xl:w-[1000px] xl:grid-cols-3 gap-y-[90px] md:gap-y-[155px] xl:gap-x-[210px] 2xl:gap-x-[280px]">
                        {FinancialProsperityData?.map((item, index) => (
                            <div key={index} className="xl:w-[300px] h-[144px] md:h-[80px] xl:h-auto">
                                <Text content={item.header} className="max-w-[200px] md:max-w-none xl:whitespace-pre-line text-gray-900 h5-regular md:h4-regular" htmlTag="h3" />
                                <Text content={item.description} className="mt-2 text-gray-700 p4-regular max-w-[350px] md:max-w-none" />
                            </div>
                        ))
                        }
                    </div>
                </div>
            </div>
            <div className="absolute hidden xl:block xl:top-[150px] w-full -left-24 maxScreen:-left-4">
                <Lottie
                    animationData={webAnimation}
                    loop={true}
                    autoplay={true}
                    className="object-none object-top w-full h-full scale-125 maxScreen:scale-125"
                />
            </div>
            <Lottie
                animationData={mobileAnimation}
                loop={true}
                autoplay={true}
                // className="object-none object-top w-full h-full scale-125 maxScreen:scale-125"
                className="xl:hidden absolute object-none left-4 md:left-[115px] -top-14 md:-top-20 xl:top-[80px]"
            />
        </div>
    );
};

export default FinancialProsperity;